

import { Component, Vue } from 'nuxt-property-decorator'
import AppImage from '~/components/AppImage/AppImage.vue'
import { AlButton } from '~/components/ui'

@Component({
  components: {
    AppImage,
    AlButton,
  },
})
export default class Media extends Vue {

}
