
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ResponsiveImage from '~/components/ResponsiveImage/ResponsiveImage.vue'

type Partner = 'salt' | 'wingo' | 'yallo' | 'talktalk' | 'mbudget' | 'quickline'

const partnerImagesMap: Record<Partner, any[]> = {
  yallo: [require('~/assets/images/provider-logos/v7/Yallo.svg')],
  salt: [require('~/assets/images/provider-logos/v7/Salt.svg')],
  wingo: [require('~/assets/images/provider-logos/v7/Wingo.svg')],
  mbudget: [require('~/assets/images/provider-logos/v7/MBudget.svg')],
  talktalk: [require('~/assets/images/provider-logos/v7/TalkTalk.svg')],
  quickline: [require('~/assets/images/provider-logos/v7/Quickline.svg')],
}

@Component({
  components: { ResponsiveImage },
})
export default class Partners extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  readonly withShadow!: boolean

  get partners () {
    return [
      {
        name: 'Wingo',
        slug: 'wingo',
        images: partnerImagesMap.wingo,
      },
      {
        name: 'Salt',
        slug: 'salt',
        images: partnerImagesMap.salt,
      },
      {
        name: 'Yallo',
        slug: 'yallo',
        images: partnerImagesMap.yallo,
      },
      {
        name: 'M-Budget',
        slug: 'm-budget',
        images: partnerImagesMap.mbudget,
      },
      {
        name: 'Quickline',
        slug: 'quickline',
        images: partnerImagesMap.quickline,
      },
      {
        name: 'TalkTalk',
        slug: 'talktalk',
        images: partnerImagesMap.talktalk,
      },
    ]
  }
}
