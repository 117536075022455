
import { Component, namespace } from 'nuxt-property-decorator'
import SavingsCounter from '~/components/SavingsCounter/SavingsCounter.vue'
import PromoBadge from '~/components/badges/PromoBadge.vue'
import { CommonState, namespace as commonStore } from '~/store/modules/Common'
import PromoThemeMixin from '~/mixins/promo-theme.mixin'
import AppImage from '~/components/AppImage/AppImage.vue'
import GoogleRatingLite from '~/components/widgets/GoogleRatingLite.vue'
import { AlButton } from '~/components/ui'
import BestDealGuarantee from '~/components/widgets/BestDealGuarantee.vue'

const CommonStore = namespace(commonStore)

@Component({
  components: {
    BestDealGuarantee,
    GoogleRatingLite,
    AppImage,
    SavingsCounter,
    PromoBadge,
    AlButton,
  },
})
export default class NewMainBanner extends PromoThemeMixin {
  @CommonStore.State('totalOrders')
  readonly totalOrders!: CommonState['totalOrders']

  @CommonStore.State('totalSavings')
  readonly totalSavings!: CommonState['totalSavings']

  onCTAClick () {
    const btnText = this.$t('home.newJumbotron.startSaving') as string

    this.$analytics.GTM.trackRaw({
      eventCategory: 'quiz',
      eventAction: 'button_click',
      eventLabel: btnText,
      language_code: this.$i18n.locale.toUpperCase(),
    })

    this.$router.push(this.localePath('quiz'))
  }
}
