
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class Feature extends Vue {
  @Prop({ required: true, default: '', type: String })
  private readonly title!: string

  @Prop({ required: true, default: '', type: String })
  private readonly description!: string

  @Prop({ required: true, default: '', type: String })
  private readonly icon!: string

  @Prop({ required: false, type: String, default: 'light' })
  private readonly theme!: 'light' | 'dark'
}
