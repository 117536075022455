
import { defineComponent } from 'vue'
import { AlButton } from '~/components/ui'

export default defineComponent({
  name: 'MainPagePlans',
  components: {
    AlButton,
  },
})
