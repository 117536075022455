
import { Component } from 'nuxt-property-decorator'
import type { MetaInfo } from 'vue-meta'
import { getCurrentInstance } from 'vue'
import { OnMounted, WithHead } from '~/core/vue.types'
import TVAdBanner from '~/components/promos/TVAdBanner.vue'
import Partners from '~/components/sections/Partners.vue'
import Credibility from '~/components/sections/Credibility.vue'
import FeaturesBanner from '~/components/sections/FeaturesBanner.vue'
import type { FeaturesBannerItem } from '~/components/sections/FeaturesBanner.vue'
import Media from '~/components/sections/Media.vue'
import ContactUsForm from '~/components/sections/forms/ContactUsForm.vue'
import BestDealGuarantee from '~/components/widgets/BestDealGuarantee.vue'
import SaveRouteMixin from '~/mixins/save-route.mixin'
import { teamMembersConfigHome } from '~/core/config/team-members-config'
import { FeatureFlags } from '~/core/config/feature-flags'
import MainBanner from '~/components/MainBanner/MainBanner.vue'
import PromoThemeMixin from '~/mixins/promo-theme.mixin'
import HowItWorks from '~/components/main-page/HowItWorks.vue'
import Plans from '~/components/main-page/Plans.vue'
import { composeSEOMetaBase, FAQItem, useSEOMetaComposer } from '~/composables/seo.composable'

const RatingSection = () => ({ component: import(/* webpackChunkName: "sections" */ '~/components/sections/rating/RatingSection.vue') })
const MembersList = () => ({ component: import(/* webpackChunkName: "sections" */ '~/components/sections/team/MembersList.vue') })
const MembersItem = () => ({ component: import(/* webpackChunkName: "sections" */ '~/components/sections/team/MembersItem.vue') })
const FaqList = () => ({ component: import(/* webpackChunkName: "sections" */ '~/components/sections/faq/FaqList.vue') })
const FaqItem = () => ({ component: import(/* webpackChunkName: "sections" */ '~/components/sections/faq/FaqItem.vue') })
const SpecialOffersList = () => ({ component: import(/* webpackChunkName: "sections" */ '~/components/sections/special-offers/SpecialOffersList.vue') })
const ClientsFeedback = () => ({ component: import(/* webpackChunkName: "sections" */ '~/components/sections/clients-feedback/ClientsFeedback.vue') })

@Component({
  components: {
    MainBanner,
    TVAdBanner,
    Partners,
    SpecialOffersList,
    Credibility,
    Media,
    RatingSection,
    FaqList,
    FaqItem,
    MembersList,
    MembersItem,
    ContactUsForm,
    BestDealGuarantee,
    FeaturesBanner,
    ClientsFeedback,
    HowItWorks,
    Plans,
  },
  mixins: [SaveRouteMixin],
})
export default class IndexPage extends PromoThemeMixin implements WithHead, OnMounted {
  private featuresBannerItems: FeaturesBannerItem[] = [
    {
      icon: require('~/assets/images/icons/features-banner/target.svg'),
      title: this.$t('credibilityCards.1.title') as string,
      description: this.$t('credibilityCards.1.description') as string,
    },
    {
      icon: require('~/assets/images/icons/features-banner/calculator.svg'),
      title: this.$t('credibilityCards.2.title') as string,
      description: this.$t('credibilityCards.2.description') as string,
    },
    {
      icon: require('~/assets/images/icons/features-banner/timer.svg'),
      title: this.$t('credibilityCards.3.title') as string,
      description: this.$t('credibilityCards.3.description') as string,
    },
    {
      icon: require('~/assets/images/icons/features-banner/flag-banner.svg'),
      title: this.$t('credibilityCards.4.title') as string,
      description: this.$t('credibilityCards.4.description') as string,
    },
  ]

  private teamMembers = teamMembersConfigHome

  private featureNewVoucherProcess = this.$featureFlags.isEnabled(FeatureFlags.NEW_VOUCHER_PROCESS)

  head (): MetaInfo {
    return useSEOMetaComposer(
      this,
      {
        title: this.$t('head.meta.index.title').toString(),
        description: this.$t('head.meta.index.description').toString(),
        route: { name: 'index' },
        faq: {
          items: this.faqList,
        },
      },
    )
  }

  mounted () {
    this.$analytics.GTM.sendPageViewEvent({
      routeName: this.$route.name as string,
      pageTitle: this.$metaInfo.title || '',
      fullPath: this.$route.fullPath,
      fromPath: this.$nuxt.context.from ? this.$nuxt.context.from.fullPath : '',
      contentGroup: 'Homepage',
    })
  }

  get faqList () {
    return [
      { question: this.$t('home.faq.accordion.title1'), answer: this.$t('home.faq.accordion.content1') },
      { question: this.$t('home.faq.accordion.title2'), answer: this.$t('home.faq.accordion.content2') },
      { question: this.$t('home.faq.accordion.title3'), answer: this.$t('home.faq.accordion.content3') },
      this.featureNewVoucherProcess && { question: this.$t('home.faq.accordion.title4'), answer: this.$t('home.faq.accordion.content4') },
      { question: this.$t('home.faq.accordion.title5'), answer: this.$t('home.faq.accordion.content5') },
      { question: this.$t('home.faq.accordion.title6'), answer: this.$t('home.faq.accordion.content6') },
      { question: this.$t('home.faq.accordion.title7'), answer: this.$t('home.faq.accordion.content7') },
    ].filter(Boolean) as FAQItem[]
  }
}
