
import { computed, getCurrentInstance, defineComponent, PropType } from 'vue'
import { AlButton } from '~/components/ui'
import { Theme } from '~/types/theme'

export default defineComponent({
  name: 'HowItWorks',
  components: {
    AlButton,
  },
  props: {
    theme: {
      type: String as PropType<Theme>,
      default: 'light',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup () {
    const instance = getCurrentInstance()

    const $t = computed(() => {
      return (key: string, props?: any) =>
        instance?.proxy?.$i18n?.t(key, props) || ''
    })

    const itemsList = computed(() => {
      return [
        {
          title: $t.value('homePage.smartMatching'),
          content: $t.value('homePage.smartMatchingDescription'),
        },
        {
          title: $t.value('homePage.easyOrdering'),
          content: $t.value('homePage.easyOrderingDescription'),
        },
        {
          title: $t.value('homePage.digitalIDSigning'),
          content: $t.value('homePage.digitalIDSigningDescription'),
        },
        {
          title: $t.value('homePage.quickActivation'),
          content: $t.value('homePage.quickActivationDescription'),
        },
      ]
    })

    return {
      $t,
      itemsList,
    }
  },
})
